import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import { memo } from 'react'

import { toggleBurgerMainMenu } from '@redux/features/modals/modals.slice'
import { useAppDispatch } from '@redux/hooks'

import { MobileMenuButtonStyled } from '@components/header/BurgerMenu/MobileMenuButton/styles'
import BarsIcon from '@iconscout/unicons/svg/line/bars.svg'

const MobileMenuButton: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const openMenu = () => dispatch(toggleBurgerMainMenu(true))

  return (
    <MobileMenuButtonStyled iconColor="primary" onClick={openMenu} type="link">
      <BarsIcon />
      <span>{t('common.menu')}</span>
    </MobileMenuButtonStyled>
  )
}

export default memo(MobileMenuButton)
